<template>
  <div>
    <TopSection from="Home" page="My Account" />
    <div :class="{'flex': $route.query.isMenu}" class="center-wrapper sign">
     <Message v-if="message" :isReg="true" :type="message.type" :message="message.message" :content="message.content" />
     <TableSideBar :items="sideBarData" v-if="$route.name.toLowerCase() === 'profile' && $route.query.isMenu" :class="{'isMenu': $route.query.isMenu}" /> 
     <div class="details-wrapper">
        <div class="details-block">
          <div class="details-header">
            <div class="heading">
              <img src="@/assets/icons/user_large.png" />
              <p v-if="$route.name.toLowerCase() === 'profile'">Update your profile</p>
              <p v-else>BECOME A RETAILER</p>
            </div>
          </div>
          <div class="center-wrapper">
            <div class="input-wrapper">
              <div class="input-container noIcon">
                <label>Email address<sup>*</sup></label>
                <input class="input-field" type="text" v-model="regForm.emailAddress" :disabled="$route.name.toLowerCase() === 'profile'">
                <p v-if="errors.hasOwnProperty('emailAddress')" class="error">Field required</p>
                <p v-else-if="errors.hasOwnProperty('emailAddressRegex')" class="error">Email address not found!</p>
              </div>
              <div class="input-container noIcon">
                <label v-if="$route.name.toLowerCase() === 'profile'">Old Password<sup>*</sup></label>
                <label v-else>Password<sup>*</sup></label>
                <input class="input-field" type="password" v-model="regForm.password">
                <p v-if="errors.hasOwnProperty('password')" class="error">Field required</p>
                <p v-if="errors.hasOwnProperty('minPassword')" class="error">Minimum 6 characters required</p>
              </div>
              <div v-if="$route.name.toLowerCase() === 'profile'" class="input-container noIcon">
                <label>New Password<sup>*</sup></label>
                <input class="input-field" type="password" v-model="regForm.newPassword">
                <p v-if="errors.hasOwnProperty('newPassword')" class="error">Field required</p>
                <p v-if="errors.hasOwnProperty('minnewPassword')" class="error">Minimum 6 characters required</p>
              </div>
              <div class="input-container noIcon">
                <label>First Name<sup>*</sup></label>
                <input class="input-field" type="text" v-model="regForm.firstName">
                <p v-if="errors.hasOwnProperty('firstName')" class="error">Field required</p>
              </div>
              <div class="input-container noIcon">
                <label>Last Name<sup>*</sup></label>
                <input class="input-field" type="text" v-model="regForm.lastName">
                <p v-if="errors.hasOwnProperty('lastName')" class="error">Field required</p>
              </div>
              <div class="input-container noIcon">
                <label>Location(postal code)<sup>*</sup></label>
                <input class="input-field" type="text" placeholder="140001" v-model="regForm.location">
                <p v-if="errors.hasOwnProperty('location')" class="error">Field required</p>
              </div>
              <div class="input-container noIcon">
                <label>Phone Number<sup>*</sup></label>
                <input class="input-field" type="tel" v-model="regForm.phoneNumber" @keyup.enter="submitForm">
                <p v-if="errors.hasOwnProperty('phoneNumber')" class="error">Field required</p>
              </div>
              <div class="input-wrapper-button reg">
                <button class="orange pointer" @click="submitForm" v-if="$route.name.toLowerCase() === 'profile'">UPDATE</button>
                <button class="orange pointer" @click="submitForm" v-else>REGISTER</button>
              </div>
            </div>
            <Loader class="loader" v-if="isLoader" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableSideBar from '@/components/molecules/TableSideBar'
import TopSection from '@/components/molecules/TopSection'
import Loader from '@/components/molecules/Loader'
import Message from '@/components/molecules/Message'
import Common from '@/mixins/Common'
export default {
  mixins: [Common],
  components: {
    TableSideBar,
    TopSection,
    Loader,
    Message
  },
  data () {
    return {
      errors: {},
      emailAddressRegex: /\S+@\S+\.\S+/,
      regForm: {
        emailAddress: '',
        password: '',
        newPassword: '',
        firstName: '',
        lastName: '',
        location: '',
        phoneNumber: ''
      }
    }
  },
  watch: {
    userDetails() {
      this.getUserDetails()
    }
  },
  metaInfo() {
    return {
      title: this.$route.name.toLowerCase() === 'profile' ? 'Update Profile' : 'Registration'
    }
  },
  computed: {
    ...mapState(['isLoader', 'message']),
    ...mapState('auth', ['userDetails'])
  },
  mounted() {
    this.getUserDetails()
  },
  methods: {
    getUserDetails() {
      if (this.$route.name.toLowerCase() === 'profile' && this.userDetails) {
        this.regForm['emailAddress'] = this.userDetails.email
        this.regForm['firstName'] = this.userDetails.fullname.split(' ')[0]
        this.regForm['lastName'] = this.userDetails.fullname.split(' ')[1]
        this.regForm['location'] = this.userDetails.pincode
        this.regForm['phoneNumber'] = this.userDetails.phoneNumber
      }
    },
    validateFields () {
      this.errors = {}
      if (!this.regForm['emailAddress']) {
        this.$set(this.errors, 'emailAddress', 'required')
      } else if (!this.emailAddressRegex.test(this.regForm['emailAddress'])) {
        this.$set(this.errors, 'emailAddressRegex', 'required')
      }
      if (!this.regForm['password']) {
        this.$set(this.errors, 'password', 'required')
      } else if (this.regForm['password'] && this.regForm['password'].length < 6) {
        this.$set(this.errors, 'minPassword', 'required')
      }
      if (this.$route.name.toLowerCase() === 'profile') {
        if (!this.regForm['newPassword']) {
          this.$set(this.errors, 'newPassword', 'required')
        } else if (this.regForm['newPassword'] && this.regForm['newPassword'].length < 6) {
          this.$set(this.errors, 'minnewPassword', 'required')
        }
      }
      if (!this.regForm['firstName']) {
        this.$set(this.errors, 'firstName', 'required')
      }
      if (!this.regForm['lastName']) {
        this.$set(this.errors, 'lastName', 'required')
      }
      if (!this.regForm['location']) {
        this.$set(this.errors, 'location', 'required')
      }
      if (!this.regForm['phoneNumber']) {
        this.$set(this.errors, 'phoneNumber', 'required')
      }
      if (Object.getOwnPropertyNames(this.errors).length === 1) {
        return true
      }
    },
    submitForm(){
      if (this.validateFields()) {
      const userData = {
        email: this.regForm['emailAddress'],
        password: this.regForm['password'],
        fullname: `${this.regForm['firstName']} ${this.regForm['lastName']}`,
        pincode: this.regForm['location'],
        phoneNumber: this.regForm['phoneNumber']
      }
        if (this.$route.name.toLowerCase() === 'profile' && this.userDetails) {
          delete userData.password
          delete userData.email
          userData.identifier = this.regForm['emailAddress']
          userData.password = this.regForm['password']
          userData.oldPassword = this.regForm['password']
          userData.newPassword = this.regForm['newPassword']
          this.$store.dispatch('auth/profileUpdate', userData)
        } else {
          this.$store.dispatch('auth/signUP', userData)
        }
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../sass/login.sass'
.center-wrapper
  &.sign
    position: relative
    &.flex
      display: flex
      .details-wrapper
        flex: 1
    .loader
      position: absolute
      bottom: 12%
      margin: 0 auto
      right: 0
      left: 0
.isMenu
  flex: 0.2
.details-wrapper, .isMenu
  margin: 5.8em 0
.details-block
  width: 70%
  margin: 0 auto
  border: 2px solid map-get($greyShades, 'greyShade3')
  @media screen and (max-width: $md-breakpoint)
    width: 100%
  .details-header
    padding: 1.5em 0
    border-bottom: 2px solid map-get($greyShades, 'greyShade3')
    background: $white
    p
      margin: 5px
      @include font-source('Poppins', 16px, 600, $black1)
  .input-wrapper
    margin: 3.8em 0 2.5em 0
  .center-wrapper
    width: 80%
</style>
